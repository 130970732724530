exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apartmany-tsx": () => import("./../../../src/pages/apartmany.tsx" /* webpackChunkName: "component---src-pages-apartmany-tsx" */),
  "component---src-pages-balicky-tsx": () => import("./../../../src/pages/balicky.tsx" /* webpackChunkName: "component---src-pages-balicky-tsx" */),
  "component---src-pages-darkove-poukazy-tsx": () => import("./../../../src/pages/darkove-poukazy.tsx" /* webpackChunkName: "component---src-pages-darkove-poukazy-tsx" */),
  "component---src-pages-fotogalerie-tsx": () => import("./../../../src/pages/fotogalerie.tsx" /* webpackChunkName: "component---src-pages-fotogalerie-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-okoli-tsx": () => import("./../../../src/pages/okoli.tsx" /* webpackChunkName: "component---src-pages-okoli-tsx" */),
  "component---src-pages-recenze-tsx": () => import("./../../../src/pages/recenze.tsx" /* webpackChunkName: "component---src-pages-recenze-tsx" */),
  "component---src-pages-rezervace-tsx": () => import("./../../../src/pages/rezervace.tsx" /* webpackChunkName: "component---src-pages-rezervace-tsx" */),
  "component---src-pages-wellness-tsx": () => import("./../../../src/pages/wellness.tsx" /* webpackChunkName: "component---src-pages-wellness-tsx" */)
}

